<template>
  <div>
    <h2 class="text-2xl font-bold mb-4">
      <span>{{ app.name }}</span>
      <span class="text-gray-700 ml-4 text-base">{{ app.info }}</span>
    </h2>

    <div class="mb-4 border-b border-gray-200">
      <nav class="flex -mb-px space-x-8">
        <a
          href="#"
          class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
          :class="{
            'border-indigo-500 text-indigo-600': currentTab === 'frame',
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
              currentTab !== 'frame',
          }"
          @click.prevent="changeTab('frame')"
          v-if="app.screens && app.screens.length > 0"
        >
          {{ $t("app") }}
        </a>
        <a
          href="#"
          class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
          :class="{
            'border-indigo-500 text-indigo-600': currentTab === 'screensavers',
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
              currentTab !== 'screensavers',
          }"
          @click.prevent="changeTab('screensavers')"
          v-if="app.screensavers && app.screensavers.length > 0"
        >
          {{ $t("screensavers") }}
        </a>
        <a
          href="#"
          class="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
          :class="{
            'border-indigo-500 text-indigo-600': currentTab === 'pdfs',
            'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
              currentTab !== 'pdfs',
          }"
          @click.prevent="changeTab('pdfs')"
          v-if="app.pdfs && app.pdfs.length > 0"
        >
          {{ $t("pdfs") }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    app: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentTab: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to AppHeader here */
</style>
