// src/store/index.js
import { createStore } from "vuex";
import axios from "axios";
import router from "../router";

const store = createStore({
  state: {
    config: {},
    apps: [],
    selectedApp: null,
    isAuthenticated: false,
  },
  mutations: {
    setConfig(state, config) {
      state.config = config;
    },
    setApps(state, apps) {
      state.apps = apps;
    },
    selectApp(state, app) {
      state.selectedApp = app;
    },
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
  },
  actions: {
    loadAppData({ commit }) {
      // get data from public/appsData
      // eslint-disable-next-line no-undef
      const { config, apps } = getAppDataFromIndex();
      commit("setConfig", config);
      commit("setApps", apps);
    },
    setSelectedApp({ commit, state }, appId) {
      const app = state.apps.find((a) => a.id === appId);
      commit("selectApp", app);
    },
    logOut({ commit }) {
      commit("selectApp", null);
      commit("setApplications", []);
      commit("setConfig", null);
      commit("setAuthentication", false);
      router.push({ name: "Login" });
    },
    async authenticate({ commit }, password) {
      try {
        const response = await axios.post(
          "https://interaktive.480hz.de/php-server/auth.php",
          new URLSearchParams({ password })
        );
        const { config, apps } = response.data;
        commit("setConfig", config);
        commit("setApps", apps);
        commit("setAuthentication", true);
      } catch (error) {
        console.error("Authentication failed:", error.response.data.error);
        commit("setAuthentication", false);
      }
    },
  },
  getters: {
    apps: (state) => state.apps,
    selectedApp: (state) => state.selectedApp,
    config: (state) => state.config,
    isAuthenticated: (state) => state.isAuthenticated,
  },
});

export default store;
