<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h1 class="text-2xl font-bold mb-6 text-center">{{ $t("login") }}</h1>
      <form @submit.prevent="login">
        <div class="mb-4">
          <label for="password" class="block text-gray-700 font-bold mb-2">{{
            $t("password")
          }}</label>
          <input
            v-model="password"
            id="password"
            type="password"
            :placeholder="$t('enter_password')"
            class="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            autocomplete
          />
        </div>
        <button
          type="submit"
          class="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
        >
          {{ $t("login") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      password: "",
    };
  },
  methods: {
    async login() {
      await this.$store.dispatch("authenticate", this.password);
      if (this.$store.getters.isAuthenticated) {
        this.$router.push("/");
      } else {
        alert("Invalid password");
      }
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
