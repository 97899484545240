<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="mb-4">
        <button
          v-for="(screen, index) in screens"
          :key="screen.title"
          @click="selectScreen(index)"
          class="mr-2 mb-2 px-4 py-2 bg-blue-500 text-white rounded hover:opacity-100"
          :class="index == selectedScreenIndex ? 'opacity-100' : 'opacity-60'"
        >
          {{ screen.title + ` (${screen.width}x${screen.height})` }}
        </button>
      </div>
      <div class="mb-4">
        <label for="scaleRange" class="block text-sm font-medium text-gray-700"
          >{{ $t("zoom") }}: {{ scale }}%</label
        >
        <input
          id="scaleRange"
          type="range"
          min="20"
          max="100"
          step="5"
          v-model="scale"
          class="mt-1 w-full"
        />
      </div>
    </div>
    <div v-if="selectedScreen">
      <iframe
        v-if="!isExternal"
        :src="`${mainPath}/${path}`"
        :style="iframeStyle"
        :width="selectedScreen.width + 'px'"
        :height="selectedScreen.height + 'px'"
        class="border"
        frameborder="0"
      ></iframe>
      <iframe
        v-else
        :src="path"
        :style="iframeStyle"
        :width="selectedScreen.width + 'px'"
        :height="selectedScreen.height + 'px'"
        class="border"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScreenSelector",
  props: {
    screens: {
      type: Array,
      required: true,
    },
    mainPath: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    defaultZoom: {
      type: Number,
      required: true,
    },
    isExternal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedScreenIndex: 0,
      scale: this.defaultZoom || 100,
    };
  },
  computed: {
    selectedScreen() {
      return this.screens[this.selectedScreenIndex];
    },
    iframeStyle() {
      return {
        transform: `scale(${this.scale / 100})`,
        transformOrigin: "top left",
      };
    },
  },
  methods: {
    selectScreen(index) {
      this.selectedScreenIndex = index;
    },
  },
};
</script>

<style scoped>
/* Add any additional styles here */
.border {
  box-sizing: content-box;
  border: 28px solid #111;
  border-radius: 15px;
  box-shadow: 2px 3px 20px #000;
}
</style>
