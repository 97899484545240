<template>
  <div class="container mx-auto p-4" v-if="isAuthenticated">
    <AppHeader :app="app" v-model:modelValue="currentTab" />

    <div v-if="currentTab === 'frame'" class="mb-4">
      <ScreenSelector
        :screens="app.screens"
        :path="app.path"
        :mainPath="config.server_path + app.mainPath"
        :defaultZoom="app.defaultZoom"
        :isExternal="app.isExternal"
      />
    </div>

    <div v-if="currentTab === 'screensavers'" class="mb-4">
      <ScreenSaver
        :screensavers="app.screensavers"
        :mainPath="config.server_path + app.mainPath"
        :defaultZoom="app.defaultZoom"
      />
    </div>

    <div v-if="currentTab === 'pdfs'" class="mb-4">
      <PDFViewer
        :pdfs="app.pdfs"
        :mainPath="config.server_path + app.mainPath"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppHeader from "../components/AppHeader.vue";
import ScreenSelector from "../components/ScreenSelector.vue";
import ScreenSaver from "../components/ScreenSaver.vue";
import PDFViewer from "../components/PDFViewer.vue";

export default {
  name: "AppDetail",
  components: {
    AppHeader,
    ScreenSelector,
    ScreenSaver,
    PDFViewer,
  },
  data() {
    return {
      currentTab: "frame", // Default tab
    };
  },
  computed: {
    ...mapGetters(["selectedApp", "config", "isAuthenticated"]),
    app() {
      return this.selectedApp || {};
    },
  },
  created() {
    if (!this.selectedApp) {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
/* Add any styles specific to AppDetail here */
</style>
