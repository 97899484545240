<template>
  <div
    class="app-box bg-white shadow-md rounded-lg overflow-hidden flex flex-col justify-between h-full border-2 List"
  >
    <div class="p-4 flex-grow">
      <h3 class="text-lg font-bold mb-2">{{ app.name }}</h3>
      <p class="text-gray-700 mb-4">{{ app.info }}</p>
    </div>
    <div class="p-4 mt-auto">
      <router-link
        :to="`/application/${app.id}`"
        v-on:click="setApp"
        class="text-blue-500 block text-center"
      >
        {{ $t("view_application") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppBox",
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setApp() {
      this.$store.dispatch("setSelectedApp", this.app.id);
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to AppBox here */
.app-box:hover {
  background-color: #fafafa;
}
</style>
