<template>
  <tr
    class="app-list-item bg-white shadow-md rounded-lg overflow-hidden mb-4 border-b-2 border-gray-50"
  >
    <td class="p-4 text-lg font-medium">{{ app.name }}</td>
    <td class="p-4">{{ app.info }}</td>
    <td class="p-4">{{ formatDate(app.lastUpdate) }}</td>
    <td class="p-4">
      <router-link
        :to="`/application/${app.id}`"
        v-on:click="setApp"
        class="text-blue-500"
      >
        View Application
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppListItem",
  props: {
    app: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date_format() {
      return this.$store.getters.config.date_format;
    },
  },
  methods: {
    formatDate(date) {
      return (
        this.$moment(date).format(this.date_format) +
        " (" +
        this.$moment(date).fromNow() +
        ")"
      );
    },
    setApp() {
      this.$store.dispatch("setSelectedApp", this.app.id);
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to AppListItem here */
.app-list-item:hover {
  background-color: #fafafa;
}
</style>
