// src/plugins/heroicons.js
import * as Heroicons from "@heroicons/vue/24/solid";

export default {
  install(app) {
    for (const [key, component] of Object.entries(Heroicons)) {
      app.component(key, component);
    }
  },
};
