<template>
  <div class="min-h-screen flex flex-col">
    <!-- Header -->
    <header class="bg-main text-white p-4">
      <nav class="container flex justify-between items-center m-auto">
        <h1 class="text-xl">{{ $t("welcome") }}</h1>
        <div>
          <router-link
            to="/"
            class="text-white hover:underline mr-4"
            v-if="isAuthenticated && !isHome"
          >
            {{ $t("home") }}
          </router-link>
          <button
            class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none"
            @click="logout"
            v-if="isAuthenticated"
          >
            {{ $t("logout") }}
          </button>
        </div>
      </nav>
    </header>

    <!-- Main Content -->
    <main class="flex-1 p-4 overflow-y-auto">
      <router-view />
    </main>

    <!-- Footer -->
    <footer class="bg-main text-white p-4 text-center">
      <p>480Hz</p>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainLayout",
  computed: {
    ...mapGetters(["isAuthenticated"]),
    isHome: function () {
      return this.$route.name === "main-page";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logOut");
    },
  },
};
</script>

<style scoped>
html,
body,
#app {
  height: 100%;
}

.main-content {
  flex: auto;
  overflow-y: auto;
}
</style>
