<template>
  <div class="mt-8">
    <h3 class="text-xl font-bold mb-4">{{ $t("pdfs") }}</h3>
    <div v-for="pdf in pdfs" :key="pdf.title" class="mb-4">
      <a
        :href="mainPath + '/' + pdf.path"
        target="_blank"
        class="text-blue-500"
        >{{ pdf.title }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PDFViewer",
  props: {
    pdfs: {
      type: Array,
      required: true,
    },
    mainPath: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add any styles specific to PDFViewer here */
</style>
