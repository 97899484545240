<template>
  <div class="app-list container mx-auto p-4" v-if="isAuthenticated">
    <div class="flex justify-between mb-4">
      <h2 class="text-2xl font-bold mb-4">{{ $t("application_list") }}</h2>
      <div class="flex justify-end mb-4">
        <button
          @click="viewMode = 'grid'"
          :class="{ 'bg-gray-300': viewMode === 'grid' }"
          class="px-4 py-2 mr-2 border rounded"
        >
          <Squares2X2Icon class="h-5 w-5" />
        </button>
        <button
          @click="viewMode = 'list'"
          :class="{ 'bg-gray-300': viewMode === 'list' }"
          class="px-4 py-2 border rounded"
        >
          <Bars3Icon class="h-5 w-5" />
        </button>
      </div>
    </div>
    <div
      v-if="viewMode === 'grid'"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
    >
      <AppBox v-for="app in apps" :key="app.id" :app="app" />
    </div>
    <div v-else>
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="py-2 px-4 bg-gray-200 text-left">
              {{ $t("app_name") }}
            </th>
            <th class="py-2 px-4 bg-gray-200 text-left">{{ $t("info") }}</th>
            <th class="py-2 px-4 bg-gray-200 text-left">
              {{ $t("last_update") }}
            </th>
            <th class="py-2 px-4 bg-gray-200 text-left">
              {{ $t("show_app") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <AppListItem v-for="app in apps" :key="app.id" :app="app" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppBox from "../components/AppBox";
import AppListItem from "../components/AppListItem.vue";
export default {
  name: "AppList",
  components: {
    AppBox,
    AppListItem,
  },
  data() {
    return {
      viewMode: "grid", // Default view mode
    };
  },
  computed: {
    ...mapGetters(["apps", "isAuthenticated"]),
  },
};
</script>

<style scoped>
/* Add any styles specific to AppList here */
</style>
