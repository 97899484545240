import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/tailwind.css";
import "./assets/styles/fonts.css";
import HeroiconsPlugin from "./plugins/heroicons";
import momentPlugin from "./plugins/moment";
import { createI18n } from "vue-i18n";
import en from "./i18n/en.json";
import de from "./i18n/de.json";

const i18n = createI18n({
  locale: "de", // Set default locale
  fallbackLocale: "en", // Set fallback locale
  messages: {
    en,
    de,
  },
});

const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(store);
app.use(HeroiconsPlugin);
app.use(momentPlugin);

// store.dispatch("authenticate"); // Load the app data

app.mount("#app");
