import { createRouter, createWebHashHistory } from "vue-router";
import AppList from "../views/AppList.vue";
import AppDetail from "../views/AppDetail.vue";
import LoginForm from "../views/LoginForm.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "main-page",
    component: AppList,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/application/:id",
    name: "application",
    component: AppDetail,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginForm,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        next();
      } else {
        next("/");
      }
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
